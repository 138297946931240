<template>
  <Breadcrumb class="shop-breadcrumb" :separator="separator">
    <BreadcrumbItem v-for="(item, index) in breadcrumbList" :to="skip ? {path: item.path,
                    query: {
                      ymsProductCategoryId:item.ymsProductCategoryId,
                      productCategoryCode: item.productCategoryCode
                    }
                    } : ''" :key="index">{{ item.name }}
    </BreadcrumbItem>
  </Breadcrumb>
</template>

<script>
import {Breadcrumb, BreadcrumbItem} from 'view-design';

export default {
  name: 'shopBreadcrumb',
  props: {
    breadcrumbList: { // 数据源
      type: Array,
      default: []
    },
    separator: { // 分隔符
      type: String,
      default: '>'
    },
    skip: { // 面包屑的标签是否可以跳转路由
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  components: {
    Breadcrumb,
    BreadcrumbItem
  }
};
</script>

<style lang="less" scoped>
.shop-breadcrumb {
  height: 40px;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;

  /deep/ .ivu-breadcrumb-item-separator {
    color: #666;
  }
}
</style>
